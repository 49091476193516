@import '~@estimateone/frontend-components/src/css/variables';
@import '../../../css/_shared/borders';

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 900px;
  height: 500px;
  display: flex;
  overflow: visible;
  transform: translate(-50%, -50%);
  border-radius: $border-radius-base;

  &:focus {
    outline: none;
  }
}

.modalContent {
  width: 400px;
  padding: $space-32;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $white;
  font: $font-regular;
  gap: $space-8;
}

.modalVideo {
  box-sizing: content-box;
  width: 500px;
  height: 500px;
  border-radius: 0 $border-radius-base $border-radius-base 0;
  background-color: $electric-025;
}

.modalImage {
  box-sizing: content-box;
  width: 500px;
  height: 500px;
  border-radius: 0 $border-radius-base $border-radius-base 0;
  background-color: $electric-025;
  object-fit: cover;
}

.overlay {
  position: fixed;
  z-index: 1050 !important;
  background-color: rgba($primary-practical-blue, 0.8);
  inset: 0;
}

.overlayTransparent {
  position: fixed;
  z-index: 1050 !important;
  inset: 0;
}

.posterImage{
  box-sizing: content-box;
  width: 500px;
  height: 500px;
  border-radius: 0 $border-radius-base $border-radius-base 0;
  background-color: $electric-025;
  object-fit: cover;
}

.closeIconPosition {
  position: absolute;
  top: 8px;
  right: -35px;
}

.closeIcon {
  margin-left: 0.5em;

  path {
    stroke: $practical-025;
    fill: $practical-025;
  }
}

.closeIcon:hover path {
  stroke: $practical-200;
  fill: $practical-200;
}

@import '~@estimateone/frontend-components/src/css/variables';

.customFormLabel {
  margin-bottom: $spacer-2;
}

.packageSelectorFieldError {
  margin-bottom: $spacer-2;
  list-style: none;
  color: $color-error;
  font: $font-p-heavy;
}

.packageOptions {
  fieldset + fieldset {
    margin-top: $spacer-3;
  }
}

.builder {
  padding: 1.2em $spacer-3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid $iron-100;
  border-radius: 4px;
  background: $white;
  line-height: 1;

  &.hasError {
    border-color: $color-error;
  }

  label {
    margin-bottom: 0;
  }
}

.preselected {
  cursor: default;
}

.phone {
  color: $iron-600;
  font-size: $font-size-sm;
  line-height: 1;
}

.withdrawn {
  background-color: $iron-025;
  color: $iron-300;
}

.withdrawnLabel {
  color: $iron-500;
}

.disabled {
  background: $iron-050;
  pointer-events: none;
}

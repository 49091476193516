@import '~@estimateone/frontend-components/src/css/variables';

.quoteViewedBuilderUpgradeHook {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  background-color: $electric-025;
}

.upgradeIcon {
  margin-right: $space-12;
  fill: $electric-500;
}

.upgradeButton {
  border-color: $electric-025;
  color: $electric-500;

  &:focus,
  &:hover {
    border-color: $electric-025;
    background-color: $electric-025;
  }

  &:active {
    border-color: $electric-050;
    background-color: $electric-050;
  }
}

.description {
  margin-bottom: 0;
  flex: 0 1 65%;
}

.modalTitle {
  margin-bottom: $space-12;
  font: $font-heading-3;
}

.modalIncluded {
  margin-bottom: $space-8;
}

.modalCheckIcon {
  width: 0.6em;
  stroke: $electric-300;
}

.explorePlanButton {
  color: white;

  &:focus {
    outline: none;
  }
}

.modalCheckList {
  margin-bottom: $space-8;
  display: flex;
  flex-direction: row;
  gap: $space-8;

  .modalCheckIcon {
    flex: 1 0 0.8em;
    stroke: $electric-300;
  }
}

.expandIcon {
  margin-left: $space-12;
  stroke-width: 0.5;
  fill: $practical-025;
}
@import '~@estimateone/frontend-components/src/css/variables';

.quoteListWrapper{
  display: flex;
  flex-direction: column;
  gap: $space-32;
}

.quoteListPage {
  display: flex;
  flex-direction: column;

  table {
    tbody tr td {
      vertical-align: top;
    }

    ul {
      list-style: none;

      li {
        padding-bottom: 1em;
        white-space: nowrap;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .projectName {
      font: $font-p-heavy;
    }

    .projectDetails {
      color: $iron-600;
      font: $font-ps-med;
    }

    .quoteAuthor {
      padding-left: $spacer-2;
      display: inline-block;
      color: $iron-600;
    }
  }
}

.noResults {
  strong {
    margin: $spacer-6 0;
    font: $font-heading-1;
  }

  span {
    margin: 0 $spacer-2;
    display: inline-block;
  }
}
